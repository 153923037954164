import React, { Component } from "react"
import { DownloadForm } from "./download-form"
import { StaticImage } from "gatsby-plugin-image"

export class DownloadBody extends Component {
    render() {
        return (
            <div className="bg-dhgray py-10 px-5 lg:px-10">
                <div className="container mx-auto">
                    <div className="lg:grid lg:grid-cols-2">
                        <div className="px-6 leading-8">
                            <h1 className="font-bold text-2xl"><span className="text-dhred">Free Guide:</span> Learn The Pros and Cons Of Selling Your House To A Local Sacramento Professional Home Buyer</h1>
                            <p className="pt-2 font-semibold text-xl text-dhred">And What 4 Hidden Costs Creep Up When Listing With An Agent Or Selling It Yourself</p>
                            <div className="py-5 flex justify-center">
                                <StaticImage src="../images/free-guide.jpeg" className="flex w-fit mx-auto" loading="eager" placeholder="none" title="Dahlia Homes" alt="Dahlia Homes" />
                            </div>
                            <p className="pt-2 font-semibold text-lg">PLUS: What big pitfalls to watch out for when you’re selling your house with a real estate agent, by yourself, or even to a professional home buyer like Dahlia Homes</p>
                            <p className="pt-2">Missing one of these critical “pitfalls” could mean more stress and expense for you when your house finally sells.</p>
                            <p className="pt-2 font-bold text-lg">There are 3 main ways to sell your California house.</p>
                            <p className="pt-2">All with their own pros and cons.</p>
                            <ul className="list-decimal ml-10">
                                <li>List it with an <strong>agent</strong></li>
                                <li>Sell it <strong>yourself</strong> (FSBO)</li>
                                <li>Sell it to a <strong>professional home buyer</strong> in California</li>
                            </ul>
                            <p className="pt-2">All three options are great in certain situations… and terrible in others.</p>
                            <p className="pt-2">Learn when to list with an agent, when to sell yourself, and when selling to a real estate investor makes the most sense.</p>
                            <p className="pt-2">Also, learn the costs associated with each option… because you’ll be surprised at what “hidden costs” there are in listing with an agent or selling it yourself that most people never even think about until it’s too late.</p>
                            <p className="pt-2 font-bold text-lg">Enter your name and email in the form to the right to download this free guide.</p>
                            <p className="pt-2">If you need to sell your house quickly… this guide walks you through why real estate agents may end up costing you tens of thousands and still end up not getting your house sold.</p>
                            <p className="pt-2">-Dahlia Homes</p>
                        </div>

                        <div className="mx-auto py-4">
                            <div className="flex justify-center">
                                <DownloadForm
                                    formName="Professional Home Buyer Guide Download Page"
                                    formHeader="Request Your FREE 'Pros, Cons, and Pitfalls' Guide Below"
                                    formBody="Just put in your name and email, click 'Submit' and we'll email you the Free Guide right away."
                                    formBodyBottom="We never rent, sell, or share your info with anyone. It's not cool and we hate spam"
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}