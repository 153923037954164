import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { DownloadBody } from "../components/download-body"

const HomeSeller = (props) => {
    return (
        <Layout>
            <Seo
                title="Download The Home Seller Guide - Dahlia Homes"
                location={props.location.pathname}
            />
            <div>
                <DownloadBody />
            </div>
        </Layout>
    )
}
export default HomeSeller